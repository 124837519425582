import {createGlobalStyle} from 'styled-components';
import {device} from './device'

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 100;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 300;
        color:#202020;
    }
    a{
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 300;
    }
    h1{
        font-size:70px;
        line-height:76px;

        @media ${device.mobileXL} {
            font-size:54px;
            line-height:60px;
        }
    }
    h2{
        font-size:48px;
        line-height:54px;
    }
    h3{
        font-size:35px;
        line-height:41px;
    }
    h4{
        font-size:30px;
        line-height:36px;
    }
    h5{
        font-size:26px;
        line-height:32px;
    }
    h6{
        font-size:18px;
        line-height:24px;
    }
    p{
        color:#5c5c5c;
        margin-bottom:26px;
        font-size:16px;
        line-height: 26px;
    }
    a{
        text-decoration:none;
    }
    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
