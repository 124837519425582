import styled from 'styled-components';
import React from 'react';

export const CommonbtnShadow = styled.div`
    background: ${props => props.bg};
    border-radius: 3px;
    height: 45px;
    margin-left: 6px;
    margin-top: -6px;
    position: absolute;
    transition: transform 0.5s;
    width: 150px;
`;
export const CommonbtnAnchor = styled.a`
    background: #f26522;
    border-radius: 3px;
    height: 45px;
    text-align:center;
    line-height: 40px;
    transition: transform 0.5s;
    color:#fff;
    text-decoration:none;
    font-size:16px;
    font-weight:400;
    position: absolute;
    width: 150px;
    :hover{
        transform: translate(6px, -6px);
        text-decoration:none;
        color:#fff;
    }

`;
export const CommonFormbtnShadow = styled.div`
    background: ${props => props.bg};
    border-radius: 3px;
    height: 45px;
    margin-left: 6px;
    margin-top: -6px;
    position: absolute;
    transition: transform 0.5s;
    width: 150px;
`;
export const CommonFormbtnAnchor = styled.button`
    background: #f26522;
    border-radius: 3px;
    height: 45px;
    text-align:center;
    line-height: 40px;
    transition: transform 0.5s;
    color:#fff;
    text-decoration:none;
    font-size:16px;
    font-weight:400;
    position: absolute;
    border:none;
    cursor:pointer;
    width: 150px;
    :hover{
        transform: translate(6px, -6px);
    }

`;

export const CommonBtn = ({buttonText,link,background}) =>(
    <div>
    <CommonbtnShadow bg={background}></CommonbtnShadow>
    <CommonbtnAnchor href={link}>{buttonText}</CommonbtnAnchor>
    </div>
)

export const CommonFormBtn = ({buttonText,link,background}) =>(
    <div>
    <CommonFormbtnShadow bg={background}></CommonFormbtnShadow>
    <CommonFormbtnAnchor type="submit">{buttonText}</CommonFormbtnAnchor>
    </div>
)


export const SectionHeading = styled.h3`
    font-size:30px;
    margin-bottom:15px;
    text-align:center;
    font-weight:300;
`;
