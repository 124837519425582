import React from 'react';
import {CommonBtn} from '../Common/common.style'
import {BannerSection,BannerWrapper,BannerLeft,BannerCustomContainer,BannerRight,
    Slidercol,Caption} from './banner.style';
import Slider from "react-slick";
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var settings = {
    dots: true,
    arrows:false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    appendDots: dots => (
        <div
            style={{
                textAlign:"left",
                position:"relative",
                bottom:"0px"
            }}
        >
            <ul style={{
                margin: "0px",
                position:"relative",
                lineHeight:"0px"
            }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div style={{paddingTop:10}}>
        <span
          style={{
            display:"inline-block",
            width: "20px",
            height:"3px",
            background:"#fff",
            borderRadius: "3px",
            opacity:0.6
          }}
        >
        </span>
        </div>
      ),
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: true,
                infinite: true
            }
        }
      ]
    };

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`{
  restaurantJson {
    Banner {
      BannerHeading
      BannerPara
      CommonBtnLabel
      Slider {
        SlideImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        CaptionHeading
        CaptionPara
      }
    }
  }
}
`);

        return (
            <BannerSection id="homeContainer">
                <BannerWrapper>
                    <BannerCustomContainer>
                        <BannerLeft>
                            <h2>{JSONData.restaurantJson.Banner.BannerHeading}</h2>
                            <p>{JSONData.restaurantJson.Banner.BannerPara}</p>
                            <CommonBtn background="#fff" buttonText={JSONData.restaurantJson.Banner.CommonBtnLabel} link="#">{JSONData.restaurantJson.Banner.CommonBtnLabel}</CommonBtn>
                        </BannerLeft>
                        <BannerRight>
                        <Slider {...settings}>
                        {
                            JSONData.restaurantJson.Banner.Slider.map((item,idx) => {
                                return (
                                    <Slidercol>
                                        <GatsbyImage
                                            image={item.SlideImg.childImageSharp.gatsbyImageData}
                                            className="sliderImg"
                                            alt="" />

                                        <Caption>
                                            <h6>
                                            {item.CaptionHeading}
                                            </h6>
                                            <p>
                                            {item.CaptionPara}
                                            </p>
                                        </Caption>
                                    </Slidercol>
                                );
                            })
                        }
                        </Slider>
                        </BannerRight>
                    </BannerCustomContainer>
                </BannerWrapper>
            </BannerSection>
        );
        }

export default Banner;
