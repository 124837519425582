import React from 'react';
import {VideoSection,VideoCol,VideoColItem,VideoPlay} from './video.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Video =()=>{
    const JSONData  = useStaticQuery(graphql`
        query {
            restaurantJson{
                    Video{
                        VideoColHeading
                    }
                }
            }
        `);
    return (
            <VideoSection>
                <Container>
                    <Row>
                        <Col md="12">
                            <VideoCol>
                                <VideoColItem>
                                    <h3>{JSONData.restaurantJson.Video.VideoColHeading}</h3>
                                    <VideoPlay/>
                                </VideoColItem>
                            </VideoCol>
                        </Col>
                    </Row>
                </Container>
            </VideoSection>
        )
    }
export default Video;
