import React,{Component} from 'react';
import {DishSliderSection,SliderCustomContainer,SliderCol,SliderHover,SliderColWrapper,PrevImg,NextImg,
    SliderWrapper,SliderWrapperHeading,NextPrev,ImgButton} from './dishslider.style'
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class DishSlider extends Component{
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){
        const settings = {
            dots: false,
            arrows:true,
            infinite: false,
            speed: 500,
            slidesToShow: 4.2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        dots: false,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        dots: false,
                        slidesToScroll: 1,
                        infinite: true,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        dots: false,
                        infinite: true,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <DishSliderSection>
                <SliderCustomContainer>
                    <SliderWrapperHeading>{this.props.restaurantJson.DishesSlider.SliderWrapperHeading}</SliderWrapperHeading>

                    <SliderWrapper>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                            this.props.restaurantJson.DishesSlider.SliderColWrapper.map((item,idx) => {
                            return (
                                <SliderColWrapper>
                                    <SliderCol>
                                        <GatsbyImage
                                            image={item.SliderImg.childImageSharp.gatsbyImageData}
                                            className="SliderImg"
                                            alt="" />
                                        <SliderHover>
                                            <h5>
                                            {item.SliderHoverText}
                                            </h5>
                                            <GatsbyImage
                                                image={item.SliderHoverIcon.childImageSharp.gatsbyImageData}
                                                className="SliderHoverIcon"
                                                alt="" />
                                        </SliderHover>
                                    </SliderCol>
                                </SliderColWrapper>
                            );
                            })
                        }
                        </Slider>
                    </SliderWrapper>
                    <NextPrev>
                        <ImgButton onClick={this.previous} aria-label="Prev Button">
                            <PrevImg
                                src={this.props.restaurantJson.DishesSlider.PrevImg}
                                alt=""
                            />
                        </ImgButton>
                        <ImgButton onClick={this.next} aria-label="Next Button">
                            <NextImg
                                src={this.props.restaurantJson.DishesSlider.NextImg}
                                alt=""
                            />
                        </ImgButton>
                    </NextPrev>
                </SliderCustomContainer>
            </DishSliderSection>
        );
    }
}

const DishSliderPage = () => (
    <StaticQuery
        query={graphql`{
  restaurantJson {
    DishesSlider {
      SliderWrapperHeading
      SliderColWrapper {
        SliderImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        SliderHoverText
        SliderHoverIcon {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <DishSlider restaurantJson={data.restaurantJson}/>
        )}
    />
  )
  export default DishSliderPage;
