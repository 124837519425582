import React from 'react';
import {AboutSection,AboutRightCol,
    AboutLeftCol,AboutCustomRow} from './about.style';
import {CommonBtn} from '../Common/common.style'
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const About =()=>{
    const JSONData  = useStaticQuery(graphql`{
  restaurantJson {
    About {
      AboutLeftImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      AboutRightColHeading
      AboutRightPara
      CommonBtnLabel
    }
  }
}
`);
    return (
        <AboutSection id="aboutContainer">
            <Container>
                <AboutCustomRow>
                    <Col md="5">
                        <AboutLeftCol>
                            <GatsbyImage
                                image={JSONData.restaurantJson.About.AboutLeftImage.childImageSharp.gatsbyImageData}
                                className="AboutLeftImage"
                                alt="" />
                        </AboutLeftCol>
                    </Col>
                    <Col md="7">
                        <AboutRightCol>
                            <h3>{JSONData.restaurantJson.About.AboutRightColHeading}</h3>
                            <p>{JSONData.restaurantJson.About.AboutRightPara}</p>
                            <CommonBtn background="#dbdbdb" buttonText={JSONData.restaurantJson.About.CommonBtnLabel} link="#">{JSONData.restaurantJson.About.CommonBtnLabel}</CommonBtn>
                        </AboutRightCol>
                    </Col>
                </AboutCustomRow>
            </Container>
        </AboutSection>
    );
    }
export default About;
