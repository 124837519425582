import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon,FooterMenu,FooterSocial,BottomFooterPara,
    BottomFooterWrapper,BottomLink} from './footer.style';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query {
        restaurantJson{
                Footer{
                    FooterCol1{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol2{
                        FooterTitle
                        FooterUl1{
                            FooterMenu
                        }
                        FooterUl2{
                            FooterMenu
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterSocial{
                            FooterSocialImg
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
  `);
    return (
            <div>
            <FooterSection>
                <Container>
                    <Row>
                        <Col md="3">
                            <FooterCol>
                                <h5>{JSONData.restaurantJson.Footer.FooterCol1.FooterTitle}</h5>
                                {
                                    JSONData.restaurantJson.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                    return <p>
                                    {item.Para}
                                    </p>
                                    })
                                }
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h5>{JSONData.restaurantJson.Footer.FooterCol2.FooterTitle}</h5>
                                <Row>
                                    <Col lg="4">
                                        <ul>
                                        {
                                            JSONData.restaurantJson.Footer.FooterCol2.FooterUl1.map((item,idx) => {
                                            return <li>
                                                <FooterMenu href="#">
                                                    {item.FooterMenu}
                                                </FooterMenu>
                                            </li>
                                            })
                                        }
                                        </ul>
                                    </Col>
                                    <Col lg="8">
                                        <ul>
                                        {
                                            JSONData.restaurantJson.Footer.FooterCol2.FooterUl2.map((item,idx) => {
                                            return <li>
                                                <FooterMenu href="#">
                                                {item.FooterMenu}
                                                </FooterMenu>
                                            </li>
                                            })
                                        }
                                        </ul>
                                    </Col>
                                </Row>
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h5>{JSONData.restaurantJson.Footer.FooterCol3.FooterTitle}</h5>
                                <ul>
                                {
                                    JSONData.restaurantJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <li>
                                        <FooterMenu href="#">
                                            {item.FooterMenu}
                                        </FooterMenu>
                                    </li>
                                    })
                                }
                                </ul>
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <h5>{JSONData.restaurantJson.Footer.FooterCol4.FooterTitle}</h5>
                                <FooterSocial href="#" aria-label="FB Footer Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Twitter Footer Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="LinkedIn Footer Link">
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Instagram Footer Link">
                                    <InstaIcon/>
                                </FooterSocial>
                            </FooterCol>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <BottomFooterWrapper>
                <Container>
                    <Row>
                        <Col md="12">
                            <BottomFooterPara>
                                {JSONData.restaurantJson.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.restaurantJson.Footer.BottomFooterLink} target="_blank">
                                {JSONData.restaurantJson.Footer.BottomFooterName}
                                </BottomLink>
                            </BottomFooterPara>
                        </Col>
                    </Row>
                </Container>
            </BottomFooterWrapper>
            </div>
        )
                    }

export default Footer;
