import React from 'react';
import {About2Section,About2RightCol,
    About2LeftCol,About2CustomRow} from './about2.style';
import {CommonBtn} from '../Common/common.style'
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const About2 =()=>{
    const JSONData  = useStaticQuery(graphql`{
  restaurantJson {
    About2 {
      About2RightColHeading
      About2RightPara
      CommonBtnLabel
      About2LeftImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <About2Section>
                <Container>
                    <About2CustomRow>
                        <Col md="7">
                            <About2RightCol>
                                <h3>{JSONData.restaurantJson.About2.About2RightColHeading}</h3>
                                <p>{JSONData.restaurantJson.About2.About2RightPara}</p>
                                <CommonBtn background="#dbdbdb" buttonText={JSONData.restaurantJson.About2.CommonBtnLabel} link="#">{JSONData.restaurantJson.About2.CommonBtnLabel}</CommonBtn>
                            </About2RightCol>
                        </Col>
                        <Col md="5">
                            <About2LeftCol>
                                <GatsbyImage
                                    image={JSONData.restaurantJson.About2.About2LeftImage.childImageSharp.gatsbyImageData}
                                    className="About2LeftImage"
                                    alt="" />
                            </About2LeftCol>
                        </Col>
                    </About2CustomRow>
                </Container>
            </About2Section>
        );
    }
export default About2;
