import React from 'react';
import {FeaturesSection,FeaturesHeadingWrapper,FeaturesListWrapper,
    FeaturesRightCol,FeaturesContent}
    from './featuredItems.style';
import {SectionHeading} from '../Common/common.style'
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Features =()=>{
    const JSONData  = useStaticQuery(graphql`{
  restaurantJson {
    Features {
      SectionHeading
      FeaturesListWrapper {
        FeaturesLeftImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        FeaturesContentTitle
        FeaturesContentPara
        FeaturesContentPrice
      }
    }
  }
}
`);

    return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <FeaturesHeadingWrapper>
                            <SectionHeading>{JSONData.restaurantJson.Features.SectionHeading}</SectionHeading>
                        </FeaturesHeadingWrapper>
                    </Col>
                </Row>

                <FeaturesListWrapper>
                    <Row>
                    {
                        JSONData.restaurantJson.Features.FeaturesListWrapper.map((item,idx) => {
                        return (
                            <Col md="12" lg="6">
                                <FeaturesRightCol>
                                    <GatsbyImage
                                        image={item.FeaturesLeftImg.childImageSharp.gatsbyImageData}
                                        className="FeaturesLeftImg"
                                        alt="" />
                                    <FeaturesContent>
                                        <h5>
                                            {item.FeaturesContentTitle}
                                        </h5>
                                        <p>
                                            {item.FeaturesContentPara}
                                        </p>
                                        <h3>
                                            {item.FeaturesContentPrice}
                                        </h3>
                                    </FeaturesContent>
                                </FeaturesRightCol>
                            </Col>
                        );
                        })
                    }
                    </Row>
                </FeaturesListWrapper>
            </Container>
        </FeaturesSection>
    );
    }
export default Features;
