import styled from 'styled-components';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {device} from '../Common/device'

export const About2Section = styled.section`
    padding:80px 0px;
    background: #f9f9f9;
    @media ${device.tablet} {
        padding-right:10px;
        padding-left: 10px;
    }
`;

export const About2CustomRow = styled(Row)`
    align-items:center;
`;

export const About2LeftCol = styled.div`
    .About2LeftImage{
        width:100%;
        margin-bottom:0px;
    }
`;

export const About2RightCol = styled.div`
    padding-right:100px;
    @media ${device.laptop} {
        padding-right:0px;
    }
    @media ${device.tablet} {
        padding-right:0px;
        margin-bottom: 140px;
    }

    h3{
        text-align:left;
        margin-bottom:30px;
    }
    p{
        text-align:left;
        margin-bottom:30px;
    }
`;
